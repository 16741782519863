import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'

// import Seo from '../components/seo'
// import Layout from '../components/layout'
// import Hero from '../components/hero'
// import Tags from '../components/tags'
import * as styles from './blog-post.module.css'

const options = {
  renderMark: {
    [MARKS.BOLD]: (text:any) => <b className="font-bold">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node:any, children:any) => {
      const uri = node.data.uri
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        )
    },
    [BLOCKS.HEADING_2]: (node:any, children:any) => {
      return <h2 className="py-5">{children}</h2>
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node:any, children:any) => {
      if(node.data.target.__typename === 'ContentfulSocialMedia'){
        return (
          <div
            // dangerouslySetInnerHTML={{ __html: node.data.target.embedCode.internal.content }}
          >
            {node.data.target.embedCode.internal.content}
          </div>
        )
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node:any, children:any) => {
      const contentType = node.data.target.file.contentType
      let uri = node.data.target.file.url
      if(contentType.startsWith('image')) {
        uri = `https:${uri}`
        return (
          <GatsbyImage alt="" image={node.data.target.gatsbyImageData} />
        )
      }
      else if(contentType.startsWith('video')) {
        return <video controls src={uri}/>
      }
    }
  },
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')
    const plainTextDescription = documentToPlainTextString(
      JSON.parse(post.description.raw)
    )
    const plainTextBody = documentToPlainTextString(JSON.parse(post.body.raw))

    return (
      <div>
        <div className={styles.container}>
          <span className={styles.meta}>
            <Link to={`/en-gb/author/${post.author.slug}`}>
              {post.author?.name}
            </Link>
            {' '}&middot;{' '}
            <Link to={`/en-gb/blog/category/${post.category.slug}`}>
              {post.category?.name}
            </Link>
            {' '}&middot;{' '}
            <time dateTime={post.rawDate}>{post.publishDate}</time> –{' '}
          </span>
          <div className={styles.article}>
            <div className={styles.body}>
              {post.body?.raw && renderRichText(post.body, options)}
            </div>
            {/* <Tags tags={post.metadata.tags} /> */}
            {(previous || next) && (
              <nav>
                <ul className={styles.articleNavigation}>
                  {previous && (
                    <li>
                      <Link to={`/en-gb/blog/${previous.slug}`} rel="prev">
                        ← {previous.title}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={`/en-gb/blog/${next.slug}`} rel="next">
                        {next.title} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      author {
        slug
        name
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
            file {
              contentType
              url
              details {
                size
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
      description {
        raw
      }
      category {
        name
        id
        slug
      }
      metadata {
        tags {
          name
        }
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
